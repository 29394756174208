import React, { useState, ChangeEvent } from "react";
import {
  ArrowLeft,
  Search,
  Upload,
  Trash2,
  ChevronDown,
  ChevronUp,
  X,
} from "lucide-react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

interface CompanyData {
  id: string;
  name: string;
  legalName: string;
  cnpj: string;
  address: string;
  cityState: string;
}

interface FileData {
  name: string;
  size: string;
  id: string;
  description: string;
  file: File;
}

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (description: string) => void;
}

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText,
  cancelText,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        <p className="text-gray-600 mb-6">{message}</p>
        <div className="flex justify-end space-x-3">
          {cancelText && (
            <button
              onClick={onClose}
              className="px-4 py-2 border rounded-lg text-gray-600 hover:bg-gray-50"
            >
              {cancelText}
            </button>
          )}

          {confirmText && (
            <button
              onClick={() => {
                onConfirm();
                onClose?.();
              }}
              className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600"
            >
              {confirmText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const UploadModal: React.FC<UploadModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [description, setDescription] = useState("");

  if (!isOpen) return null;

  const handleSubmit = () => {
    onConfirm(description);
    setDescription("");
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Descrição do Arquivo</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Digite uma descrição para o arquivo..."
          className="w-full p-3 border rounded-lg mb-4 h-32 resize-none"
        />
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded-lg text-gray-600 hover:bg-gray-50"
          >
            Cancelar
          </button>
          <button
            onClick={handleSubmit}
            disabled={!description.trim()}
            className="px-4 py-2 bg-orange-500 text-white rounded-lg disabled:bg-gray-300"
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

const BasicAuth = `Basic ${btoa("admin:supersecret")}`;

const RegistrationFlow: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [cnpj, setCnpj] = useState<string>("");
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [files, setFiles] = useState<FileData[]>([]);
  const [isCompanyExpanded, setIsCompanyExpanded] = useState<boolean>(false);
  const [isAddressExpanded, setIsAddressExpanded] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingFile, setPendingFile] = useState<File | null>(null);

  const [confirmationModal, setConfirmationModal] =
    useState<ConfirmationModalProps>({
      isOpen: false,
      title: "",
      message: "",
      onConfirm: () => {},
      onClose: () => {},
    });

  const formatCNPJ = (value: string): string => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .substring(0, 18);
  };

  const handleCNPJChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const formatted = formatCNPJ(e.target.value);
    setCnpj(formatted);
    setCompanyData(null);
    setIsCompanyExpanded(false);
    setIsAddressExpanded(false);
  };

  const handleSearch = async (): Promise<void> => {
    setLoading(true);

    try {
      const cnpjQuery = cnpj.replace(/\D/g, "");

      const response = await axios
        .get("https://api.softhubs.com.br/clientes", {
          params: { cnpj: cnpjQuery, registro: cnpjQuery },
          headers: {
            Accept: "application/json",
            Authorization: BasicAuth,
          },
        })
        .then((res) => {
          return { statusCode: res.status, data: res.data };
        })
        .catch((err) => {
          return { statusCode: err.status, data: err.message };
        });

      if (response && response.statusCode == 200) {
        const company = response.data[0];
        setCompanyData({
          id: company.id || "N/A",
          name: company.fantasia || "N/A",
          legalName: company.razao || "N/A",
          cnpj: company.cnpj || "N/A",
          address: company.endereco || "N/A",
          cityState: company.cidade || "N/A",
        });
        setIsCompanyExpanded(true);
        setIsAddressExpanded(true);
      } else {
        setConfirmationModal({
          confirmText: "OK",
          cancelText: "",
          isOpen: true,
          title: "Atenção",
          message: "CNPJ não encontrado. Verifique e tente novamente.",
          onConfirm: () => {
            setCompanyData(null);
          },
        });
      }
    } catch (error) {
      setConfirmationModal({
        confirmText: "OK",
        cancelText: "",
        isOpen: true,
        title: "Falha",
        message: JSON.stringify(error || ""),
        onConfirm: () => {
          setCompanyData(null);
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNext = (): void => {
    if (companyData) {
      setStep(2);
    }
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files[0];
    setPendingFile(file);
    setIsModalOpen(true);

    // Reset input value to allow uploading the same file again
    e.target.value = "";
  };

  const removeFile = (fileId: string): void => {
    setFiles(files.filter((file) => file.id !== fileId));
  };

  const handleUploadFileApi = async (): Promise<void> => {
    if (files.length === 0) {
      setConfirmationModal({
        isOpen: true,
        title: "Atenção",
        message: "Selecione pelo menos um arquivo para continuar.",
        onConfirm: () => {},
      });
      /*  alert("Selecione pelo menos um arquivo para continuar."); */
      return;
    }

    setLoading(true);

    try {
      for (const file of files) {
        const formData = new FormData();

        // Extrair a extensão do arquivo original
        const originalExtension = file.file.name.split(".").pop();

        // Criar o novo nome e preservar a extensão
        const newFileName = `${uuidv4()}.${originalExtension}`;

        // Renomear o arquivo
        const renamedFile = new File([file.file], newFileName, {
          type: file.file.type, // Preserve o tipo MIME original
        });

        const sanitizedCNPJ = cnpj.replace(/\D/g, "");
        formData.append("identificacao", sanitizedCNPJ);
        formData.append("descricao", file.description);
        formData.append("file", renamedFile); // Adiciona o arquivo renomeado

        const response = await axios.post(
          "https://api.softhubs.com.br/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: BasicAuth,
            },
            maxBodyLength: Infinity,
          }
        );

        console.log(
          `Upload realizado com sucesso para o arquivo ${file.name}:`,
          response.data
        );

        /* 
        const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [cnpj, setCnpj] = useState<string>("");
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [files, setFiles] = useState<FileData[]>([]);
  const [isCompanyExpanded, setIsCompanyExpanded] = useState<boolean>(false);
  const [isAddressExpanded, setIsAddressExpanded] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingFile, setPendingFile] = useState<File | null>(null);
        */

        setStep(1);
        setCnpj("");
        setCompanyData(null);
        setFiles([]);
      }

      setConfirmationModal({
        confirmText: "OK",
        cancelText: "",
        isOpen: true,
        title: "Sucesso",
        message: "Todos os arquivos foram enviados com sucesso!",
        onConfirm: () => {
          setStep(1);
          setCnpj("");
          setCompanyData(null);
          setFiles([]);
        },
      });

      /*  alert("Todos os arquivos foram enviados com sucesso!"); */
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
      alert("Erro ao enviar arquivos. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const renderCompanyInfo = (): JSX.Element | null => {
    if (!companyData || loading) return null;

    return (
      <div className="mt-4 space-y-2">
        <div className="border rounded-lg overflow-hidden">
          <button
            onClick={() => setIsCompanyExpanded(!isCompanyExpanded)}
            className="w-full p-4 flex justify-between items-center bg-white"
          >
            <div>
              <p className="text-sm font-medium text-left">Dados da Empresa</p>
            </div>
            {isCompanyExpanded ? (
              <ChevronUp className="w-5 h-5 text-gray-400" />
            ) : (
              <ChevronDown className="w-5 h-5 text-gray-400" />
            )}
          </button>

          {isCompanyExpanded && (
            <div className="p-4 bg-gray-50 border-t">
              <p className="text-xs font-bold text-gray-600">
                #{companyData.id}
              </p>
              <p className="text-xs text-gray-600">{companyData.name}</p>
              <p className="text-xs text-gray-600">{companyData.legalName}</p>
              <p className="text-xs text-gray-600">{companyData.cnpj}</p>
            </div>
          )}
        </div>

        <div className="border rounded-lg overflow-hidden">
          <button
            onClick={() => setIsAddressExpanded(!isAddressExpanded)}
            className="w-full p-4 flex justify-between items-center bg-white"
          >
            <p className="text-sm font-medium">Endereço</p>
            {isAddressExpanded ? (
              <ChevronUp className="w-5 h-5 text-gray-400" />
            ) : (
              <ChevronDown className="w-5 h-5 text-gray-400" />
            )}
          </button>

          {isAddressExpanded && (
            <div className="p-4 bg-gray-50 border-t">
              <p className="text-xs">{companyData.address}</p>
              <p className="text-xs text-gray-600">{companyData.cityState}</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderStep = (): JSX.Element | null => {
    switch (step) {
      case 1:
        return (
          <div className="flex flex-col h-full">
            <div className="mb-8">
              <h2 className="text-lg font-medium mb-1">
                Pesquise o CNPJ da empresa
              </h2>
              <p className="text-sm text-gray-600">Etapa {step} de 2</p>
              <div className="w-full h-1 bg-gray-200 mt-2">
                <div
                  className="h-full bg-orange-500"
                  style={{ width: "50%" }}
                />
              </div>
            </div>

            <div className="flex-grow">
              <label className="block text-sm mb-2">CNPJ</label>
              <div className="relative">
                <input
                  type="text"
                  value={cnpj}
                  onChange={handleCNPJChange}
                  placeholder="00.000.000/0000-00"
                  className="w-full p-3 pr-12 border rounded-lg"
                  maxLength={18}
                />
                <button
                  onClick={handleSearch}
                  disabled={loading || cnpj.length < 14}
                  className="absolute right-2 top-1/2 -translate-y-1/2 p-2 bg-orange-500 rounded-lg text-white disabled:bg-gray-300"
                >
                  <Search className="w-5 h-5" />
                </button>
              </div>

              {loading && (
                <div className="flex justify-center items-center mt-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-orange-500" />
                </div>
              )}

              {renderCompanyInfo()}
            </div>

            <button
              onClick={handleNext}
              disabled={!companyData || loading}
              className={`w-full py-4 rounded-lg mt-auto text-white
                ${
                  !companyData || loading
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-orange-500"
                }`}
            >
              Avançar
            </button>
          </div>
        );

      case 2:
        return (
          <div className="flex flex-col h-full">
            <div className="mb-8">
              <h2 className="text-lg font-medium mb-1">Anexo</h2>
              <p className="text-sm text-gray-600">Etapa 2 de 2</p>
              <div className="w-full h-1 bg-gray-200 mt-2">
                <div
                  className="h-full bg-orange-500"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="flex-grow">
              <h3 className="text-sm font-medium mb-2">Upload de arquivos</h3>

              {renderFileList()}

              <label className="block w-full mt-4">
                <div className="w-full p-4 border-2 border-dashed border-gray-300 rounded-lg text-center cursor-pointer hover:border-orange-500">
                  <Upload className="w-5 h-5 mx-auto mb-2 text-orange-500" />
                  <span className="text-sm text-gray-600">
                    Anexar imagens ou vídeos
                  </span>
                </div>
                <input
                  type="file"
                  className="hidden"
                  multiple
                  onChange={handleFileUpload}
                  accept="image/*,video/*"
                  disabled={loading}
                />
              </label>

              {loading && (
                <div className="flex justify-center items-center mt-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-orange-500" />
                </div>
              )}
            </div>

            <button
              className="w-full bg-orange-500 text-white py-4 rounded-lg mt-auto disabled:bg-gray-300"
              onClick={handleUploadFileApi}
              disabled={files.length === 0 || loading}
            >
              Finalizar
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  const handleModalConfirm = (description: string) => {
    if (!pendingFile) return;

    const newFile: FileData = {
      name: pendingFile.name,
      size: `${Math.round(pendingFile.size / 1024)}KB`,
      id: Math.random().toString(36).substring(7),
      description: description,
      file: pendingFile,
    };

    setFiles((prevFiles) => [...prevFiles, newFile]);
    setPendingFile(null);
  };

  // Update the files mapping in case 2 to show descriptions
  const renderFileList = () => {
    return files.map((file) => (
      <div
        key={file.id}
        className="flex flex-col p-3 bg-gray-50 rounded-lg mb-2"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-sm">{file.name}</span>
            <span className="text-xs text-gray-500 ml-2">({file.size})</span>
          </div>
          <button
            onClick={() => removeFile(file.id)}
            disabled={loading}
            className="text-red-500"
          >
            <Trash2 className="w-4 h-4" />
          </button>
        </div>
        <p className="text-xs text-gray-600 mt-1">{file.description}</p>
      </div>
    ));
  };

  return (
    <div className="max-w-md mx-auto h-screen bg-white p-4 flex flex-col">
      <header className="flex items-center mb-6">
        <button
          onClick={() => (step > 1 ? setStep(step - 1) : null)}
          className="p-2"
        >
          <ArrowLeft className="w-6 h-6" />
        </button>
        <img src="softcom.png" alt="Logo" className="mx-auto" />
      </header>
      {renderStep()}
      <UploadModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setPendingFile(null);
        }}
        onConfirm={handleModalConfirm}
      />

      <ConfirmationModal
        confirmText={confirmationModal.confirmText}
        cancelText={confirmationModal.cancelText}
        isOpen={confirmationModal.isOpen}
        onClose={() =>
          setConfirmationModal((prev) => ({ ...prev, isOpen: false }))
        }
        onConfirm={confirmationModal.onConfirm}
        title={confirmationModal.title}
        message={confirmationModal.message}
      />
    </div>
  );
};

export default RegistrationFlow;
