import { Routes, Route, Navigate } from "react-router-dom";
import RegistrationFlow from "../pages/home";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RegistrationFlow />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
